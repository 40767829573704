<div *ngIf="!isEmpty(information) && !isEmpty(configuracion) ">
    <app-header (redirectSection)="goSection($event)" [information]="information"
        [configuracion]="configuracion"></app-header>
</div>
<div class="mainScreen animate__animated animate__fadeIn" id="portadaSM"
    [ngStyle]="{ 'background-image': 'url('+urlPortada+ ')' }"
    style="background-size: cover; background-position: center; width: 100%; z-index: 9999 !important;">
    <div class="container-fluid" #inicio>
        <div class="row divSelect">
            <div class="col-xxl-10 col-xl-10  col-lg-10 col-12 ">
                <div class="container-fluid ">
                    <div class="row  divSelect  " style="padding-bottom: 7em;">
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12  ">
                            <div class="centered-div">
                                <h3 class="pb-5 h3Portada colorLandScape ">Bienvenido a nuestra web</h3>
                                <div class="d-flex  animate__animated animate__backInLeft" style="">
                                    <h1 class="h1Portada colorLandScape">
                                        {{information.slogan}} | <span class="nameEmpresa">{{companyNane | uppercase
                                            }}</span>
                                    </h1>
                                </div>
                                <div class="col-11  animate__animated animate__backInLeft">
                                    <div class="pt-5 pb-2 ">
                                        <p class="colorLandScape" style="line-height: 1.35em; text-align: justify;  ">
                                            {{information.detalleSlogan}} 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-5 pt-2">
                                    <div class="d-flex" style="justify-content: space-between;">
                                        <button class="btn btnIcoSM " (click)="redirecTO(information.facebook )">
                                            <i class="bi bi-facebook icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM" (click)="redirecTO(information.instagram)">
                                            <i class="bi bi-instagram icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM " (click)="redirecTO(information.tiktok)">
                                            <i class="bi bi-tiktok icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM " (click)="redirecTO( information.youtube )">
                                            <i class="bi bi-youtube icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM " (click)="redirecTO(information.twitter)">
                                            <i class="bi bi-twitter icoSM"></i>
                                        </button>
                                    </div>

                                </div>
                                <div class="col-xxl-9 col-xl-10 col-lg-11 col-md-12 col-12 pt-3" *ngIf="idShop==70">
                                    <div class="row divSelect">
                                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  mb-2 " id="btnServices"
                                            *ngFor="let btn of botones; let i = index">
                                            <button (click)="redirecTO(btn.url)" 
                                                class="btn btn-md rounded-pill btn-block  btnExplora fw-semibold " id="btnEffect{{i}}"
                                                [ngStyle]="{'background-color':btn.colorBoton, 'color':btn.colorLetra}">
                                                {{btn.titulo}}
                                            </button>
                                        </div>
                                        <!-- <div class="col">
                                            <button class="btn bnt-sm rounded-pill  btnVerifica  btn-block py-1">
                                                Verifica el estado de tu vehículo
                                            </button>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-xl-10 col-lg-11 col-md-12 col-12 pt-3" *ngIf="idShop!=70">
                                    <div class="row divSelect">
                                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  mb-2"
                                            *ngFor="let btn of botones">
                                            <button (click)="redirecTO(btn.url)"
                                                class="btn btn-sm rounded-pill btn-block   "
                                                [ngStyle]="{'background-color':btn.colorBoton, 'color':btn.colorLetra}">
                                                {{btn.titulo}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-xxl-5 col-xl-5 col-lg-4 col-md-8 col-12 ">
                            <div class="centered-div promos " style=" position: relative;" *ngIf="information.verPromos==1">
                                <div class="text-center w-100">
                                    <ngb-carousel id="ngbDiapositiva" style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3); border-radius: 12px;">
                                        <ng-template ngbSlide *ngFor="let i of imagenBanner">
                                            <h3 class=" divSelect   titlePromo "></h3>
                                            <img class="auto  animate__animated animate__fadeIn"
                                                (click)="verDetallePromo(i,modalPromo)"
                                                style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3); border-radius: 12px;"
                                                [src]="urlBase+i.url+'?alt=media'" id="imgBanner"
                                                alt="Random first slide">
                                            <div class="carousel-caption"></div>
                                        </ng-template>
                                    </ngb-carousel>
                                </div>
                                <!-- <ng-container *ngIf="!isEmpty(imagenBanner) " >
                                    <app-collage  [banners]="imagenBanner" ></app-collage>

                                </ng-container> -->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-5" id="nosotros2" #nosotros >
    <div class="row divSelect">
        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-11 col-12  ">
            <div class="container-fluid ">
                <div class="row">
                    <!-- <div class="col-1"></div> -->
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                        <div class=" d-flex " style="justify-content: end;">
                            <span class="rounded-pill px-2"
                                style="background-color: #ebebeb; color: gray;font-weight: 400; font-size: 14px; ">
                                {{information.fraseClave}}
                            </span>
                        </div>
                        <div>
                            <h1 class="nameEmpresa textSecondary" style="text-align: end;">{{companyNane | uppercase}}
                            </h1>
                            <hr>
                        </div>
                        <p class="" style="text-align: justify;">
                            {{information.fraseEmpresa}}
                        </p>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ">
                        <div class="">
                            <ng-container  *ngIf="this.urlVideoInformation1">
                                <iframe width="560" height="315" [src]="videoY | safe" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                                </iframe>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-11 col-12  mt-5" *ngIf="idShop==70 || idShop==78">
            <div class="container-fluid ">
                <div class="row divSelect ">

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">

                        <div class=" d-flex divCodQR " style="justify-content: end;">
                            <span class="rounded-pill px-2"
                                style="background-color: #ebebeb; color: gray;font-weight: 400; font-size: 14px; ">
                                QR
                            </span>
                        </div>
                        <div>
                            <h1 class="nameEmpresa textSecondary" style="text-align: end;"> Escanea el código</h1>
                            <hr>
                        </div>
                        <p class="" style="text-align: justify;">
                            Da click en el 
                            <a *ngIf="idShop==70"
                                href="https://www.google.com/maps/search/DJ%20MANTENIMIEMTO%20AUTOMOTRIZ/@-3.9726,-79.2174,17z"
                                target="_blank">Enlace
                            </a> 
                            <a *ngIf="idShop==78"
                                href="https://maps.app.goo.gl/LpfyKuDZqnGWBnBn8"
                                target="_blank">Enlace
                            </a> 
                                para que te redirija al Maps de nuestra ubicación
                        </p>
                        <div class="divSelect"  *ngIf="idShop==70">
                            <img src="../../../assets/img/frame.png" alt="" class="imgQRDir">
                        </div>

                        <div class="divSelect"  *ngIf="idShop==78">
                            <img src="../../../assets/img/QRClinicaiautomotriz.png" alt="" class="imgQRDir">
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  " *ngIf="this.urlVideoInformation2">
                        <div class=" d-flex " style="justify-content: end;">
                            <span class="rounded-pill px-2"
                                style="background-color: #ebebeb; color: gray;font-weight: 400; font-size: 14px; ">
                                Video
                            </span>
                        </div>
                        <div>
                            <h1 class="nameEmpresa textSecondary" style="text-align: end;"> ¿Cómo llegar?</h1>
                            <hr>
                        </div>
                        <p class="" style="text-align: justify;">
                            Da click en el video para que veas lo fácil que es llegar a nuestras instalaciones.
                        </p>
                        <div class=""  >
                            <iframe width="560" height="315" [src]="video2 | safe" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row py-5 my-5 divSelect">
        <div class="col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-12  ">
            <div class="container-fluid "  *ngIf="idShop==78" >
                <div class="row divSelect">
                    <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-12">
                        <div class="row">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-0"></div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                <div class="card pt-5" style="border-color: transparent; ">
                             
                                 
                                    <div class="divSelect ">
                                        <p  style="font-size: 40px !important;">
                                            {{information.detalleEmpresa}}
                                        </p>
                                    </div>
                                    <div class="divSelect ">
                                        <p class=" " style="text-align: justify;">
                                            {{information.detallefraseClave}}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-12  ">
                        <div class="row ">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 " *ngFor="let item of servicios">
                                <div class="card text-start m-1  " style="position: relative;" id="cardPor" (click)="verDetalleServicio(item,modalDetalleServicio)">
                                    <img *ngIf="item.imagen!=null" class="card-img-top"
                                        [src]="urlBase+item.imagen+'?alt=media'" alt="Title" />
                                    <img *ngIf="item.imagen==null" class="card-img-top"
                                        src="../../../assets/img/imgDefault/sinProd.png" alt="Title" />
                                    <div class="card-body">
                                        <h6 class="card-title">{{item.nombre}}</h6>
                                        <p class="card-text" style="line-height: 1.25em; font-size: 13px; text-align: justify; ">
                                            {{transformText(item.detalle)}}</p>
                                        <button (click)="verDetalleServicio(item,modalDetalleServicio)"
                                            class="btn btn-sm  bgVerDetalle text-white" title="VER DETALLE COMPLETO"
                                            style=" position: absolute; top: 0; right: 0;  ">
                                            <i class="bi bi-eye-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                
                </div>
            </div>

            <div class="container-fluid "  *ngIf="idShop!=78" >
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  ">
                        <div class="row ">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 " *ngFor="let item of servicios">
                                <div class="card text-start m-1  " style="position: relative;" id="cardPor" (click)="verDetalleServicio(item,modalDetalleServicio)">
                                    <img *ngIf="item.imagen!=null" class="card-img-top"
                                        [src]="urlBase+item.imagen+'?alt=media'" alt="Title" />
                                    <img *ngIf="item.imagen==null" class="card-img-top"
                                        src="../../../assets/img/imgDefault/sinProd.png" alt="Title" />
                                    <div class="card-body">
                                        <h6 class="card-title">{{item.nombre}}</h6>
                                        <p class="card-text" style="line-height: 1.25em; font-size: 13px; text-align: justify; ">
                                            {{transformText(item.detalle)}}</p>
                                        <button (click)="verDetalleServicio(item,modalDetalleServicio)"
                                            class="btn btn-sm  bgVerDetalle text-white" title="VER DETALLE COMPLETO"
                                            style=" position: absolute; top: 0; right: 0;  ">
                                            <i class="bi bi-eye-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
                        <div class="row">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-0"></div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                <div class="card pt-5" style="border-color: transparent; ">
                                    <div class="divRigth2">
                                        <p class="nuestroServicios">
                                            {{information.detalleEmpresa}}
                                            <!-- <strong class="nameEmpresa2" [ngStyle]="{ 'color':botones[0].colorBoton }" >{{companyNane}}</strong> -->
                                            <strong class="nameEmpresa2 btnEmpresaColor ">{{companyNane |
                                                uppercase}}</strong>
                                        </p>
                                        <p class=" " style="text-align: justify;">
                                            {{information.detallefraseClave}}
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  pb-5" #mision>
        <div class="row  ">
            <div class="col-12 ">
                <div class="card rowCard">
                    <div class="row mb-5">
                        <div class="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-12 ">
                            <!-- <img src="../../../assets/img/imgVM.png" alt="" class="imgMV" > -->
                            <div class="divCarMV divSelect " style="position: relative;">
                                <ng-container *ngIf="idShop==78"  >
                                    <div class="row"> 
                                        <div class="col-12 divSelect">
                                            <img *ngIf="information.imagenMision"
                                            [src]="urlBase+information.imagenMision+'?alt=media'" alt="" class="imgMV2 ">
                                        <img *ngIf="!information.imagenMision" src="../../../assets/img/imgDefault/historia.jpg"
                                            alt="" class="imgMV2 ">
                                        </div>
                                        <div class="col-12 divSelect">
                                            <img *ngIf="information.imagenVision"
                                            [src]="urlBase+information.imagenVision+'?alt=media'" alt="" class="imgMV2 ">
                                        <img *ngIf="!information.imagenVision" src="../../../assets/img/imgDefault/historia.jpg"
                                            alt="" class="imgMV2 ">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="idShop!=78"  >
                                <img *ngIf="information.imagenMision"
                                    [src]="urlBase+information.imagenMision+'?alt=media'" alt="" class="imgMV2 ">
                                <img *ngIf="!information.imagenMision" src="../../../assets/img/imgDefault/historia.jpg"
                                    alt="" class="imgMV2 ">
                                </ng-container>
                  
                                    

                            </div>
                            <div class="divCarSM  ">
                                <div class="divSelect">
                                    <img *ngIf="information.imagenMision"
                                        [src]="urlBase+information.imagenMision+'?alt=media'" alt="" class="imgSM">

                                </div>

                            </div>
                        </div>
                        <div class="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-12 ">
                            <div class="p-5 ">
                                <div class="card mt-5  " style="background-color: transparent; border: none;">
                                    <div class="row ">
                                        <div class=" col-12 pb-4">
                                            <span *ngIf="idShop==70" class="spanMV parrafoInfo"> <i
                                                    class="bi bi-bullseye icoMV"></i> Misión:
                                                Eficiencia y Seguridad </span>
                                            <span  *ngIf="idShop!=70" class="spanMV parrafoInfo"> <i class="bi bi-bullseye icoMV"></i>
                                                Misión</span>
                                            <p class="pt-3 parrafoHistoria " style="text-align: justify;" >
                                                {{information.mision}}
                                            </p>
                                        </div>
                                        <div class="col-12 pb-4">
                                            <span *ngIf="idShop==70" class="spanMV parrafoInfo"> <i
                                                    class="bi bi-eye icoMV"></i> Visión:
                                                Liderazgo en Mantenimiento </span>
                                            <span *ngIf="idShop!=70" class="spanMV parrafoInfo"> <i class="bi bi-eye icoMV"></i> Visión
                                            </span>
                                            <p class="pt-3 parrafoHistoria" style="text-align: justify;">
                                                {{information.vision}}
                                            </p>
                                        </div>
                                        <div class="col-12 ">
                                            <span *ngIf="idShop==70" class="spanMV parrafoInfo"> <i
                                                    class="bi bi-gem icoMV"></i> Valores:
                                                Compromiso y Honestidad </span>
                                            <span  *ngIf="idShop!=70"class="spanMV parrafoInfo"> <i class="bi bi-gem icoMV"></i>
                                                Valores</span>
                                            <!-- <p class="pt-3 parrafoHistoria" style="text-align: justify;" id="resultado">
                                                {{(information.valores)}}
                                            </p> -->
                                            <div class="whitespace-pre-line">
                                                <p class=" parrafoHistoria" style="text-align: justify;" *ngFor="let linea of formatearTextoConSaltos(information.valores)">
                                                  {{linea}}
                                                  <br>
                                                </p>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-whats-app></app-whats-app>

<!------------------------------------ MARCAS ------------------------------------>

<div *ngIf="marcas.length > 0" class="mx-2">
    <h2 class="text-center " style="font-family: 'Montserrat';">Nuestras <strong class="nuestraMarca">Marcas de
        Confianza</strong></h2>
<div class="container my-5">
    <owl-carousel-o [options]="marcasPatrocinadoras">
        <ng-template carouselSlide *ngFor="let p of marcas; let i=index">
            <div class=" col " style="margin-bottom: 2em;">
                <div class=" classContImg divSelect " type="button">
                    <img class=" imgMarca " *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                        src="{{urlBase}}{{p.logo}}?alt=media" alt="">
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
</div>

<!-- ---------------------------------- TESTIMONIOS ---------------------------------- -->

<div  *ngIf="testimonios.length > 0" class="mx-2" >
    <h2 class="text-center mt-5 mb-2 " style="font-family: 'Montserrat';">Lo que dicen <strong class="nuestraMarca">Nuestros
        Clientes</strong></h2>
<div class="divSelect ">
    <span class="text-center" style="font-family: 'Montserrat';">Confiabilidad probada por líderes del mercado</span>

</div>
<div class="container mt-4">

    <div class="container-fluid  pt-1 horizontal-scrollable">
        <owl-carousel-o [options]="testimoniosCarousel">
            <ng-template carouselSlide *ngFor="let p of testimonios; let i=index">
                <div class="divSelect" >
                    <div class=" col-4 " style="margin-bottom: 2em;">
                        <div class=" classContImg divSelect " type="button">
                            <img *ngIf="p.logo" class="imgLogoTestimonio"  (click)="seeDetailTestimonio(p,i)" [ngClass]="{'selected': selectedImageIndex === i}"
                                src="{{urlBase}}{{p.logo}}?alt=media" alt="">
                            <img *ngIf="!p.logo" class="imgLogoTestimonio"  (click)="seeDetailTestimonio(p,i)" [ngClass]="{'selected': selectedImageIndex === i}"
                                src="{{urlBase}}{{p.imagen}}?alt=media" alt="">
                        </div>
                    </div>
                </div>

            </ng-template>
        </owl-carousel-o>
    </div>

    <!-- <div class="container-fluid mt-5  horizontal-scrollable2"> -->
    <div class="container-fluid mt-5  horizontal-scrollable2 ">
        <div class="row my-5" >
            <ng-container class="" *ngFor="let p2 of testimonios2; let i=index" >
                <div class="col-lg-12 my-3 " >
                    <div class="row">
                        <div class="divSelect" >
                            <div class="col-10 "  *ngIf="p2.logo" >
                                <div class="gallery ">
                                    <div class="card border-none shadow cardMargin " 
                                    [ngClass]="{
                                        'animate__animated  animate__bounceInRight ': selectedTestimonioIndex === i
                                        
                                      }"
                                        style="border-radius: 16px; border: none;">
                                        <div class="row" >
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 " style="margin: 0 !important; padding: 0 !important; ">
                                                <img src="{{urlBase}}{{p2?.imagen}}?alt=media"
                                                    class="imgCardTestimonio " alt="">
                                            </div>
                                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                                <div class="container-fluid">
                                                    <div class="">
                                                        <div class="mx-3" style="display: flex; justify-content: start !important; float: lef; ">
                                                            <img  src="{{urlBase}}{{p2?.logo}}?alt=media"
                                                                class="imgLogoTestimonio " alt="">
                                                        </div>
                                                        <div class="divP">
                                                            <h6>{{p2?.titulo}}</h6>
                                                        </div>
                                                        <div class="divP">
                                                            <p>{{p2?.descripcion}}</p>
                                                        </div>
                                                        <div class="">
                                                            <div style="line-height: 5px;">
                                                                <p style="font-size: 15px; " >{{p2?.autor}}</p>
                                                                <p style="font-size: 12px; font-weight: 600; " >{{p2?.cargo}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                  </div>
                            </div>
                            <div class="col-10" *ngIf="!p2.logo" >
                                <div class="row">
                                    <div class="divSelect" >
                                        <div class="col-7 ">
                                            <img src="{{urlBase}}{{p2?.imagen}}?alt=media"
                                            class="imgCardTestimonio " alt=""> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <!-- <div class="col-lg-8 my-3" >
                    <div class="card border-none shadow  " 
                    [ngClass]="{
                        'animate__animated animate__fadeIn  animate__zoomIn ': selectedTestimonioIndex === i,
                        'difuminado ': selectedTestimonioIndex != i
                      }"
                        style="border-radius: 16px; border: none;">
                        <div class="row" >
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 " style="margin: 0 !important; padding: 0 !important; ">
                                <img src="{{urlBase}}{{p2?.imagen}}?alt=media"
                                    class="imgCardTestimonio " alt="">
                            </div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                <div class="container-fluid">
                                    <div class="">
                                        <div class="mx-3" style="display: flex; justify-content: start !important; float: lef; ">
                                            <img src="{{urlBase}}{{p2?.logo}}?alt=media"
                                                class="imgLogoTestimonio " alt="">
                                        </div>
                                        <div class="divP">
                                            <h6>{{p2?.titulo}}</h6>
                                        </div>
                                        <div class="divP">
                                            <p>{{p2?.descripcion}}</p>
                                        </div>
                                        <div class="">
                                            <div style="line-height: 5px;">
                                                <p style="font-size: 15px; " >{{p2?.autor}}</p>
                                                <p style="font-size: 12px; font-weight: 600; " >{{p2?.cargo}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </ng-container>
        </div>
    </div>
</div>
</div>



<div #contacto>
    <app-footer *ngIf="configuracion" [informacion]="information" [configuracion]="configuracion"></app-footer>
</div>

<ng-template #modalDetalleServicio class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="row w-100 mt-2">
        <div class="col-12" style="display: flex; justify-content: end; ">
            <button style="display: none;">

            </button>
            <button class="btn btn-sm rounded-circle btnClose  " (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </button>
        </div>
    </div>
    <div class="modal-body  ">

        <img [src]="urlBase+servicioDefault.imagen+'?alt=media'" class="rounded-3 "
            style="object-fit: contain;width: 100%; height: 33em; border-radius: 8px; " alt="">
            <div class="mt-4 p-2"  style="font-family: 'Montserrat';" >
                <h3 class="textSecondary mb-2">{{servicioDefault.nombre}}</h3>

                <p class=" mt-2" style="font-weight: 400; font-size: 14px; ">{{servicioDefault.detalle}}</p>
            </div>

    </div>
</ng-template>

<ng-template #modalPromo class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="row w-100 mt-2">
        <div class="col-12" style="display: flex; justify-content: end; ">
            <button style="display: none;">

            </button>
            <button class="btn btn-sm rounded-circle btnClose  " (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </button>
        </div>
    </div>

    <div class="modal-body  ">
        <img [src]="urlBase+promoDefault.url+'?alt=media'" class="rounded-2 "
            style="object-fit: contain;width: 100%; height: 33em; border-radius: 8px; " alt="">
    </div>
</ng-template>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>